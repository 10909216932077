<template>
  <el-row
    type="flex"
    justify="center"
    :class="['data-table', { 'data__table--selectable': selection !== 'none' }]"
  >
    <el-col :span="24" class="data-table__container">
      <el-row type="flex" justify="space-between" class="data-table__header">
        <el-col class="data-table__header__settings">
          <slot v-if="selectedRows.length" name="edit-tools" />

          <template v-else-if="data.length">
            <el-popover v-model="showSortPopover" placement="bottom-start">
              <conditions
                v-model="sorts"
                :options="labeledColumns"
                :operators="[
                  { name: 'ascending', label: $t('sortOperators.ascending') },
                  { name: 'descending', label: $t('sortOperators.descending') },
                ]"
                :add-label="$t('addSort')"
                :option-placeholder="$t('selectColumn')"
                :operator-placeholder="$t('selectDirection')"
                no-values
                show-actions
                prevent-duplication
                @cancel="showSortPopover = false"
                @apply="handleSortApply"
              />
              <el-button slot="reference" size="small" class="el-button_icon--prefix">
                <icon height="16" width="16" name="order-vertical" />
                {{ $t('sort') }}
              </el-button>
            </el-popover>
            <el-popover v-model="showFilterPopover" placement="bottom-start">
              <conditions
                v-model="filters"
                :options="labeledColumns"
                :add-label="$t('addFilter')"
                :option-placeholder="$t('selectColumn')"
                show-actions
                @cancel="showFilterPopover = false"
                @apply="handleFilterApply"
              />
              <el-button slot="reference" size="small" class="el-button_icon--prefix">
                <icon height="16" width="16" name="filter" />
                {{ $t('filters') }}
              </el-button>
            </el-popover>
            <slot name="tools" />
          </template>
        </el-col>
        <el-col class="data-table__header__tools">
          <template>
            <el-input v-model="searchQuery" size="small" class="data-table__header__search">
              <icon slot="prefix" height="16" width="16" name="search" />
            </el-input>
            <el-tooltip
              v-model="showUpdatedTooltip"
              :content="`${$t('updated')} ${updatedAgo}`"
              placement="bottom-end"
              @mouseenter.native="updatedAgo = $timeago.format(updated, $i18n.locale)"
            >
              <el-button size="small" @click="refreshData">
                <icon
                  :class="{ 'svg-icon--spin': refreshLoading }"
                  height="16"
                  width="16"
                  name="history"
                />
              </el-button>
            </el-tooltip>
          </template>
          <slot name="action-tools" />
        </el-col>
      </el-row>
      <el-table
        ref="table"
        v-loading="loading"
        border
        max-height="600px"
        :data="filteredData"
        tooltip-effect="dark"
        row-key="id"
        @selection-change="handleSelectionChange"
        @cell-click="
          selection === 'single'
            ? $emit('selection-change', $event)
            : $refs.table.toggleRowSelection($event)
        "
        v-on="events"
      >
        <el-table-column v-if="selection === 'multiple'" type="selection" width="55" />
        <el-table-column
          v-for="(column, index) in labeledColumns"
          :key="column.name + index"
          :prop="column.name"
          :formatter="formatCell"
          :class-name="column.class"
          v-bind="column"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="column.name === 'status'"
              size="small"
              :type="statusTagTypes[scope.row[scope.column.property]]"
            >
              {{ $t(`data.statuses.${scope.row[scope.column.property]}`) }}
            </el-tag>
            <span v-else>{{ scope.row[scope.column.property] }}</span>
          </template>
        </el-table-column>

        <div v-if="!loading" slot="empty" class="data-table__empty">
          <div class="data-table__empty__img"></div>
          <div class="data-table__empty__content">
            <span>{{ $t(searchQuery ? notFoundText : emptyText) }}</span>
            <slot v-if="!searchQuery" name="empty" />
          </div>
        </div>
      </el-table>
    </el-col>
  </el-row>
</template>

<script>
import Conditions from './Conditions'

export default {
  name: 'DataTable',

  components: {
    Conditions,
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
    dataColumns: {
      type: Array,
      required: true,
    },
    noColumnLabels: Boolean,

    loading: Boolean,

    emptyText: {
      type: String,
      required: true,
    },
    notFoundText: {
      type: String,
      required: true,
    },

    events: Object,

    selection: {
      type: String,
      validator: val => ['none', 'multiple', 'single'].includes(val),
      default: 'none',
    },
  },

  data() {
    return {
      refreshLoading: false,

      showSortPopover: false,
      showFilterPopover: false,
      filters: [{ option: '', operator: '', value: '' }],
      sorts: [{ option: '', operator: '' }],

      selectedRows: [],

      searchQuery: '',

      applyFilters: false,

      showUpdatedTooltip: false,
      updated: '',
      updatedAgo: '',

      statusTagTypes: {
        pending: 'info',
        active: 'success',
        completed: 'success',
        working: 'info',
        stalled: 'warning',
        deactivated: 'danger',
        aborted: 'danger',
      },
    }
  },

  computed: {
    filteredData() {
      if (this.searchQuery) {
        return this.data.filter(data =>
          Object.values(data).some(val =>
            val
              .toString()
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())
          )
        )
      }
      return this.data
    },
    labeledColumns() {
      return this.dataColumns.map(column => ({
        name: column,
        label: this.noColumnLabels ? column : this.$i18n.t(`data.columns.${column}`),
      }))
    },
  },

  watch: {
    data: {
      handler() {
        this.updated = Date.now()
        this.updatedAgo = this.$timeago.format(this.updated, this.$i18n.locale)
        this.refreshLoading = false
        this.clearSelection()
      },
      deep: true,
      immediate: true,
    },
  },

  beforeDestroy() {
    this.$timeago.cancel()
  },

  methods: {
    clearSelection() {
      this.$refs.table && this.$refs.table.clearSelection()
    },
    async refreshData() {
      this.showUpdatedTooltip = false
      this.refreshLoading = true
      this.$emit('refresh')
    },
    handleSelectionChange(value) {
      if (this.selection === 'multiple') {
        this.selectedRows = value
        this.$emit('selection-change', value)
      }
    },
    handleSortApply(conditions) {
      this.showSortPopover = false
      this.sorts = conditions
      conditions.forEach(condition => {
        this.$refs.table.sort(condition.option, condition.operator)
      })
    },
    handleFilterApply(conditions) {
      this.showFilterPopover = false
      this.filters = conditions
    },
    formatCell(row, column, value) {
      if (!isNaN(parseFloat(value)) && isFinite(value)) {
        return value.toLocaleString(this.$i18n.locale)
      }
      return value
    },
  },
}
</script>

<i18n>
{
  "en": {
    "filters": "Filters",
    "sort": "Sort",
    "sortOperators": {
      "ascending": "ascending",
      "descending": "descending"
    },
    "addSort": "Add sort",
    "addFilter": "Add filter",
    "selectColumn": "Select column",
    "selectDirection": "Select direction"
  },
  "ru": {
    "filters": "Фильтры",
    "sort": "Сортировка",
    "sortOperators": {
      "ascending": "по возрастанию",
      "descending": "по убыванию"
    },
    "addSort": "Добавить сортировку",
    "addFilter": "Добавить фильтр",
    "selectColumn": "Выберите колонку",
    "selectDirection": "Выберите направление"
  }
}
</i18n>

<style lang="scss">
.data__table--selectable {
  .el-table__row {
    cursor: pointer;
  }
}
.data-table__container {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: white;
  padding-bottom: 4px;
  .el-table {
    height: 100%;
  }
}
.data-table__header {
  width: 100%;
  height: auto;
  padding: $spacing-m;
}
.data-table__header__settings {
  display: flex;
  > * {
    margin-left: $spacing-s;
  }
  > *:first-child {
    margin-left: 0;
  }
}
.data-table__header__tools {
  display: flex;
  justify-content: flex-end;
  > * {
    margin-left: $spacing-s;
  }
}
.data-table__icon {
  margin-right: $spacing-xs;
}
.data-table__empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: $spacing-s;
}
.data-table__empty__img {
  width: 396px;
  height: 254px;
  background: url(../assets/images/notFound.svg) no-repeat no-repeat;
  background-size: contain;
  margin-top: $spacing-2xl;
  margin-bottom: $spacing-s;
}
.data-table__empty__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.data-table__header__search {
  height: 100%;
  max-width: 180px;
}
</style>
