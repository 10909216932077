<template>
  <div class="conditions">
    <span v-if="title">{{ title }}</span>
    <ul v-if="conditions.length > 0" class="conditions__list">
      <li
        v-for="(condition, index) in conditions"
        :key="condition.option + index"
        class="conditions__item"
      >
        <el-select
          v-model="condition.option"
          :placeholder="optionPlaceholder || $t('selectOption')"
          size="small"
          @change="handleChange"
        >
          <slot name="options">
            <el-option
              v-for="option in preventDuplication ? filteredOptions[index] : options"
              :key="option.name"
              :label="option.label"
              :value="option.name"
            />
          </slot>
        </el-select>
        <el-select
          v-model="condition.operator"
          :placeholder="operatorPlaceholder || $t('selectOperator')"
          size="small"
          @change="handleChange"
        >
          <slot name="operators">
            <el-option
              v-for="operator in computedOperators"
              :key="operator.name"
              :label="operator.label"
              :value="operator.name"
            />
          </slot>
        </el-select>
        <el-select
          v-if="$slots.values || (values && values[condition.option])"
          v-model="condition.value"
          :placeholder="valuePlaceholder || $t('selectValue')"
          :disabled="['exists', 'notExists'].includes(condition.operator)"
          size="small"
          @change="handleChange"
        >
          <slot name="values" :option="condition.option">
            <el-option
              v-for="conditionValue in values[condition.option]"
              :key="conditionValue"
              :label="conditionValue"
              :value="conditionValue"
            />
          </slot>
        </el-select>
        <template v-else-if="!noValues">
          <el-input
            v-if="!condition.operator.includes('Than')"
            v-model="condition.value"
            :placeholder="valuePlaceholder || $t('enterValue')"
            :disabled="['exists', 'notExists'].includes(condition.operator)"
            size="small"
            @change="handleChange"
          />
          <el-input-number
            v-else
            v-model="condition.value"
            :placeholder="valuePlaceholder || $t('enterValue')"
            size="small"
            @change="handleChange"
          />
        </template>
        <el-button size="small" @click="removeCondition(index)">
          <icon width="16" height="16" name="close" />
        </el-button>
      </li>
    </ul>
    <div class="conditions__footer">
      <el-button
        :disabled="addDisabled"
        class="conditions__add-item el-button_icon--prefix"
        size="small"
        @click="addCondition"
      >
        <icon width="16" height="16" name="add-circle" />
        {{ addLabel || $t('addCondition') }}
      </el-button>
      <div v-if="showActions" class="conditions__footer__actions">
        <el-button size="small" @click="handleCancel">{{ $t('actions.cancel') }}</el-button>
        <el-button
          :disabled="submitDisabled"
          type="primary"
          size="small"
          @click="$emit('apply', conditions)"
          >{{ $t('actions.apply') }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import isEqual from 'lodash.isequal'

export default {
  name: 'Conditions',

  props: {
    title: String,
    value: Array,
    options: Array,
    values: Object,
    operators: Array,
    noValues: Boolean,
    addLabel: String,
    showActions: Boolean,

    optionPlaceholder: String,
    operatorPlaceholder: String,
    valuePlaceholder: String,

    preventDuplication: Boolean,
  },

  data() {
    return {
      conditions: [],
      defaultOperators: [
        'equals',
        'notEquals',
        'lessThan',
        'greaterThan',
        'lessThanOrEqual',
        'greaterThanOrEqual',
        'contains',
        'notContains',
        'startsWith',
        'endsWith',
        'exists',
        'notExists',
      ],
    }
  },

  computed: {
    addDisabled() {
      return (
        this.conditions.length > 0 &&
        Object.values(this.conditions[this.conditions.length - 1]).some(val => val !== 0 && !val)
      )
    },
    submitDisabled() {
      return (
        isEqual(this.conditions, this.value) ||
        (this.conditions.length > 0 &&
          Object.values(this.conditions[this.conditions.length - 1]).includes(''))
      )
    },
    computedOperators() {
      if (this.operators) {
        return this.operators
      }

      return this.defaultOperators.map(name => ({
        name,
        label: this.$i18n.t(`operators.${name}`),
      }))
    },
    filteredOptions() {
      return this.conditions.map(condition =>
        this.options.filter(
          option =>
            condition.option === option.name ||
            !this.conditions.some(condition => condition.option === option.name)
        )
      )
    },
  },

  watch: {
    value: {
      handler(val) {
        if (val) {
          this.setConditions()
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    setConditions() {
      this.conditions = JSON.parse(JSON.stringify(this.value))
    },
    addCondition() {
      const newCondition = { option: '', operator: '' }
      if (!this.noValues) {
        newCondition.value = ''
      }
      this.conditions.push(newCondition)
      this.handleChange()
    },
    removeCondition(index) {
      this.conditions.splice(index, 1)
      this.handleChange()
    },
    handleChange() {
      this.$emit('change', this.conditions)
      !this.showActions && this.$emit('input', this.conditions)
    },
    handleCancel() {
      this.$emit('cancel')
      this.setConditions()
    },
  },
}
</script>

<i18n>
{
  "en": {
    "operators": {
      "equals": "equals",
      "notEquals": "not equals",
      "lessThan": "less than",
      "greaterThan": "greater than",
      "lessThanOrEqual": "less than or equal",
      "greaterThanOrEqual": "greater than or equal",
      "contains": "contains",
      "notContains": "not contains",
      "startsWith": "starts with",
      "endsWith": "ends with",
      "exists": "exists",
      "notExists": "not exists"
    },
    "addCondition": "Add condition",
    "selectOption": "Select option",
    "selectOperator": "Select operator",
    "selectValue": "Select value",
    "enterValue": "Enter value"
  },
  "ru": {
    "operators": {
      "equals": "равно",
      "notEquals": "не равно",
      "lessThan": "меньше чем",
      "greaterThan": "больше чем",
      "lessThanOrEqual": "меньше чем или равно",
      "greaterThanOrEqual": "больше чем или равно",
      "contains": "содержит",
      "notContains": "не содержит",
      "startsWith": "начинается с",
      "endsWith": "заканчивается",
      "exists": "существует",
      "notExists": "не существует"
    },
    "addCondition": "Добавить условие",
    "selectOption": "Выберите опцию",
    "selectOperator": "Выберите оператор",
    "selectValue": "Выберите значение",
    "enterValue": "Введите значение"
  }
}
</i18n>

<style lang="scss">
.el-popover .conditions {
  width: 280px;
}
.conditions {
  display: flex;
  flex-direction: column;
  min-width: 340px;
  & > * {
    margin-bottom: $spacing-s;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
  .el-input__suffix {
    background-color: white;
    border-bottom: 1px solid #ebedf6;
    border-top: 1px solid #ebedf6;
  }
}
.conditions__list {
  & > * {
    margin-bottom: $spacing-s;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
}
.conditions__item {
  display: flex;
  align-items: center;
  list-style: none;
  & > * {
    flex: 1;
    margin-right: $spacing-xs;
  }
  & > *:last-child {
    flex: none;
    margin-right: 0;
  }
}
.conditions__add-item.el-button {
  height: auto;
  text-align: left;
}
.conditions__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.conditions__footer__btn-destructive {
  margin-right: auto;
}
</style>
